var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    {
      attrs: {
        title:
          _vm.id === null
            ? _vm.$t("institution_create")
            : _vm.$t("institution_edit"),
      },
    },
    [
      _c(
        "vs-tabs",
        {
          attrs: { color: _vm.colorx },
          model: {
            value: _vm.tabIndex,
            callback: function ($$v) {
              _vm.tabIndex = $$v
            },
            expression: "tabIndex",
          },
        },
        [
          _c("vs-tab", { attrs: { label: "Instituição" } }, [
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:191",
                        expression: "'required|max:191'",
                      },
                    ],
                    class: _vm.requiredClass(_vm.name),
                    attrs: { label: _vm.$t("name"), name: "name" },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("name"),
                          expression: "errors.has('name')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("name")))]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col w-1/2 md:w-4/6 sm:w-9/12 lg:w-10/12" },
                [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showOrgGrid = true
                        },
                      },
                    },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate:orgId",
                            value: "required",
                            expression: "'required'",
                            arg: "orgId",
                          },
                        ],
                        class: _vm.requiredClass(_vm.orgId),
                        attrs: {
                          readonly: "",
                          label: _vm.$t("organization"),
                          name: "organization",
                        },
                        model: {
                          value: _vm.orgName,
                          callback: function ($$v) {
                            _vm.orgName = $$v
                          },
                          expression: "orgName",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("organization_id"),
                              expression: "errors.has('organization_id')",
                            },
                          ],
                          staticClass: "text-danger text-sm vx-col",
                        },
                        [_vm._v(_vm._s(_vm.errors.first("organization_id")))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "vx-col w-1/2 md:w-2/6 sm:w-3/12 lg:w-2/12 flex items-end",
                },
                [
                  _c(
                    "vs-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: "institutions.organization.grid",
                          expression: "'institutions.organization.grid'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { icon: "search", type: "border" },
                      on: {
                        click: function ($event) {
                          _vm.showOrgGrid = true
                        },
                      },
                    },
                    [_vm._v("Selecionar")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-row mb-6" }),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "float-right",
                      attrs: { color: "primary", type: "border" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(_vm._s(_vm.$t("action.back")))]
                  ),
                  _c(
                    "vs-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: "institutions.create",
                          expression: "'institutions.create'",
                        },
                      ],
                      staticClass: "float-right mr-2",
                      attrs: { disabled: !_vm.validateForm },
                      on: { click: _vm.create },
                    },
                    [_vm._v(_vm._s(_vm.$t("action.save")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm.id
            ? _c(
                "vs-tab",
                { attrs: { label: "Usuários" } },
                [
                  _c("div", { staticClass: "vx-row mb-6" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: { label: "E-mail", name: "email" },
                          on: { input: _vm.userEmailChanged },
                          model: {
                            value: _vm.user.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "email", $$v)
                            },
                            expression: "user.email",
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("userEmail"),
                                expression: "errors.has('userEmail')",
                              },
                            ],
                            staticClass: "text-danger text-sm",
                          },
                          [_vm._v(_vm._s(_vm.errors.first("userEmail")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "vx-row mb-6" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            disabled: _vm.attachingUser,
                            label: "Nome",
                            name: "userName",
                          },
                          model: {
                            value: _vm.user.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "name", $$v)
                            },
                            expression: "user.name",
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("userName"),
                                expression: "errors.has('userName')",
                              },
                            ],
                            staticClass: "text-danger text-sm",
                          },
                          [_vm._v(_vm._s(_vm.errors.first("userName")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "vx-row float-right gap-2" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c(
                          "vs-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "institution_users.create",
                                expression: "'institution_users.create'",
                              },
                            ],
                            staticClass: "float-right",
                            attrs: {
                              type: "border",
                              "icon-pack": "feather",
                              icon: "icon-file-plus",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showUserImportPopup = true
                              },
                            },
                          },
                          [_vm._v("Importar")]
                        ),
                        _c(
                          "vs-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "institution_users.create",
                                expression: "'institution_users.create'",
                              },
                            ],
                            staticClass: "float-right mr-2",
                            attrs: {
                              disabled:
                                !(_vm.attachingUser || _vm.creatingUser) ||
                                _vm.invalidUser,
                              "icon-pack": "feather",
                              icon: "icon-plus",
                            },
                            on: { click: _vm.addUser },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.creatingUser
                                  ? "Criar e Vincular"
                                  : "Vincular"
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "vx-row mb-4" }, [
                    _c("div", { staticClass: "vx-col w-full float-right" }, [
                      _c("label", { class: _vm.addUserInformationClasses() }, [
                        _vm._v(
                          " " + _vm._s(_vm.addUserInformationText()) + " "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm.usersToImport.length > 0
                    ? _c("vs-divider", [_vm._v("Usuários para importar")])
                    : _vm._e(),
                  _vm.usersToImport.length > 0
                    ? _c(
                        "vs-table",
                        {
                          key: _vm.importGridKey,
                          attrs: { data: _vm.usersToImport },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ data }) {
                                  return _vm._l(data, function (tr, i) {
                                    return _c(
                                      "vs-tr",
                                      {
                                        key: i,
                                        class: _vm.importClassStatus(tr),
                                      },
                                      [
                                        _c("vs-td", [_vm._v(_vm._s(tr.name))]),
                                        _c("vs-td", [_vm._v(_vm._s(tr.email))]),
                                        _c("vs-td", [_vm._v(_vm._s(_vm.name))]),
                                        _c("vs-td", [_vm._v(_vm._s(tr.roles))]),
                                        _c("vs-td", [
                                          _vm._v(
                                            _vm._s(_vm.importStatusText(tr))
                                          ),
                                        ]),
                                        _c(
                                          "vs-td",
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                icon: "Trash2Icon",
                                                svgClasses:
                                                  "h-5 w-5 mb-1 mr-3 stroke-current text-danger cursor-pointer",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeUserToImport(
                                                    i
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  })
                                },
                              },
                            ],
                            null,
                            false,
                            2453741498
                          ),
                        },
                        [
                          _c(
                            "template",
                            { slot: "thead" },
                            [
                              _c("vs-th", { attrs: { width: "35%" } }, [
                                _vm._v("Nome"),
                              ]),
                              _c("vs-th", { attrs: { width: "15%" } }, [
                                _vm._v("Email"),
                              ]),
                              _c("vs-th", { attrs: { width: "20%" } }, [
                                _vm._v("Instituição"),
                              ]),
                              _c("vs-th", { attrs: { width: "5%" } }, [
                                _vm._v("Grupos"),
                              ]),
                              _c("vs-th", { attrs: { width: "15%" } }, [
                                _vm._v("Status"),
                              ]),
                              _c("vs-th", { attrs: { width: "10%" } }, [
                                _vm._v("Ação"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.usersToImport.length > 0
                    ? _c(
                        "div",
                        { staticClass: "vx-row float-right gap-2 mt-4 mb-4" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c(
                                "vs-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: "institution_users.create",
                                      expression: "'institution_users.create'",
                                    },
                                  ],
                                  staticClass: "float-right",
                                  attrs: {
                                    type: "border",
                                    "icon-pack": "feather",
                                    icon: "icon-file-plus",
                                  },
                                  on: { click: _vm.importUsers },
                                },
                                [_vm._v("Finalizar importação")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("vs-divider", [_vm._v("Usuários vinculados")]),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("Grid", {
                          key: _vm.gridKey,
                          attrs: {
                            showTotalText: "Total usuários vinculados",
                            service: _vm.service,
                            route_name: "institution_users",
                            route_grid_path: `/${_vm.id}/users/grid`,
                            hide_edit: "true",
                            delegate: { destroy: () => _vm.removeUser },
                            column_formats: {
                              "pivot.created_at": (data) => {
                                return _vm.$utils.format.date(data)
                              },
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: _vm.$t("organizacoes"), active: _vm.showOrgGrid },
          on: {
            "update:active": function ($event) {
              _vm.showOrgGrid = $event
            },
          },
        },
        [
          _c("Grid", {
            attrs: {
              service: _vm.orgService,
              route_name: "institutions",
              hide_actions: "true",
              delegate: { itemSelected: () => _vm.handleOrgSelection },
            },
          }),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticStyle: { "overflow-y": "auto", "z-index": "54000" },
          attrs: {
            title: _vm.$t("importar-usuarios"),
            active: _vm.showUserImportPopup,
          },
          on: {
            "update:active": function ($event) {
              _vm.showUserImportPopup = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center" },
            [
              _c("ImportExcel", {
                attrs: {
                  exampleUrl: _vm.importExampleUrl,
                  templateFile: "educatena-modelo-importacao-usuarios.xlsx",
                  templateMessage: _vm.$t("clique-aqui-baixar-modelo"),
                  onSuccess: _vm.onSuccess,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }